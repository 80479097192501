var render = function () {
  var _vm$analytics$consump, _vm$analytics$consump2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-accounts-consumptions-page"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-chart-column",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function fn() {
        return [_vm._v("View " + _vm._s(_vm.account.type !== 'solar' ? 'consumption' : 'generation') + " data relating to this account.")];
      },
      proxy: true
    }])
  }, [[_vm._v(_vm._s(_vm.account.type !== 'solar' ? 'Consumption' : 'Generation'))]], 2), _c('div', {
    staticClass: "bg-lighter rounded-md px-4 py-3 mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Date Range")]), _c('FormItem', {
    staticClass: "mb-0",
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "id": "dateRange",
      "type": "dateRangePicker",
      "disabled": _vm.loadingAction.getConsumptions,
      "date-format": "DD/MM/YYYY"
    },
    on: {
      "input": function input() {
        return _vm.refresh();
      }
    },
    model: {
      value: _vm.filters.dateRange,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "dateRange", $$v);
      },
      expression: "filters.dateRange"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Granularity")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "granularity",
      "type": "select",
      "options": [{
        label: 'Half-hourly',
        value: 'halfhourly'
      }, {
        label: 'Hourly',
        value: 'hourly'
      }, {
        label: 'Daily',
        value: 'daily'
      }, {
        label: 'Weekly',
        value: 'weekly'
      }, {
        label: 'Monthly',
        value: 'monthly'
      }, {
        label: 'Quarterly',
        value: 'quarterly'
      }, {
        label: 'Annually',
        value: 'yearly'
      }],
      "disabled": _vm.loadingAction.getConsumptions
    },
    on: {
      "input": function input() {
        return _vm.refresh();
      }
    },
    model: {
      value: _vm.filters.granularity,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "granularity", $$v);
      },
      expression: "filters.granularity"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Source")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "source",
      "type": "select",
      "options": [{
        label: 'Combined',
        value: 'combined'
      }, {
        label: 'Invoice',
        value: 'invoice'
      }, {
        label: 'Readings',
        value: 'reading'
      }, {
        label: 'Custom',
        value: 'custom'
      }, {
        label: 'HH Data',
        value: 'hh'
      }],
      "disabled": _vm.loadingAction.getConsumptions
    },
    on: {
      "input": function input() {
        return _vm.refresh();
      }
    },
    model: {
      value: _vm.filters.source,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "source", $$v);
      },
      expression: "filters.source"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-grow-1 align-items-center justify-content-end"
  }, [_c('a', {
    staticClass: "font-w600",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.showMoreFilters = !_vm.showMoreFilters;
      }
    }
  }, [_vm._v(_vm._s(_vm.showMoreFilters ? 'Hide' : 'More') + " Filters "), _c('i', {
    staticClass: "fa fa-chevron-down ml-1",
    class: _vm.showMoreFilters ? 'fa-chevron-right' : 'fa-chevron-down'
  })])])]), _vm.showMoreFilters ? _c('div', {
    staticClass: "d-flex align-items-center mt-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Highlight")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "highlight",
      "type": "select",
      "placeholder": "Select...",
      "options": [{
        label: 'None',
        value: null
      }, {
        label: 'Source',
        value: 'source'
      }, {
        label: 'Estimated',
        value: 'estimated'
      }],
      "disabled": _vm.loadingAction.getConsumptions
    },
    on: {
      "input": function input() {
        return _vm.refresh();
      }
    },
    model: {
      value: _vm.filters.highlight,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "highlight", $$v);
      },
      expression: "filters.highlight"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Graph Type")]), _c('a', {
    staticClass: "font-w600 px-3 py-1",
    class: {
      'bg-primary text-white border rounded-md': _vm.filters.chartType === 'bar'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.filters.chartType = 'bar';
      }
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-chart-column mr-1"
  }), _vm._v(" Bar")]), _c('a', {
    staticClass: "font-w600 px-3 py-1",
    class: {
      'bg-primary text-white border rounded-md': _vm.filters.chartType === 'line'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.filters.chartType = 'line';
      }
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-chart-line mr-1"
  }), _vm._v(" Line")])])]) : _vm._e()]), _vm.account.type !== 'waste' ? _c('div', [_vm.analyticsLoading.getAnalytics['consumption'] ? _c('div', {
    staticClass: "mb-4"
  }, [_c('Spinner')], 1) : (_vm$analytics$consump = _vm.analytics['consumption']) !== null && _vm$analytics$consump !== void 0 && (_vm$analytics$consump2 = _vm$analytics$consump.data) !== null && _vm$analytics$consump2 !== void 0 && _vm$analytics$consump2.length ? _c('Chart', {
    staticClass: "mb-4",
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "option": _vm.consumptionChart
    }
  }) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No consumption data found" + _vm._s(Object.keys(_vm.filters).some(function (key) {
    return _vm.filters[key];
  }) ? ' within the selected filters' : '') + ".")])]), _vm.analytics['consumption'] ? _c('DefaultSummary', {
    staticClass: "mb-4",
    attrs: {
      "account": _vm.account,
      "type": _vm.account.type,
      "data": _vm.analytics['consumption'],
      "loading": _vm.analyticsLoading.getAnalytics['consumption'],
      "options": {
        filters: _vm.filters
      }
    }
  }) : _vm._e()], 1) : _c('div', [_vm.consumptions.length > 0 && !_vm.loadingAction.getConsumptions ? _c('div', [_vm.account.type === 'waste' ? _c('WasteSummary', {
    attrs: {
      "account": _vm.account,
      "consumptions": _vm.consumptions,
      "options": {
        filters: _vm.filters
      }
    }
  }) : _c('DefaultSummary', {
    attrs: {
      "account": _vm.account,
      "type": _vm.account.type,
      "data": _vm.analytics['consumption'] || false,
      "options": {
        filters: _vm.filters
      }
    }
  })], 1) : _vm.loadingAction.getConsumptions ? _c('div', {
    staticClass: "mb-4"
  }, [_c('Spinner')], 1) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No consumption data found" + _vm._s(Object.keys(_vm.filters).some(function (key) {
    return _vm.filters[key];
  }) ? ' within the selected filters' : '') + ".")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }